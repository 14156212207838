import React, { useMemo } from 'react'
import ContentLayout from 'components/ContentLayout'

import { title } from './index'
import ModuleThanks from '../Thanks'

const Thanks = ({ pageContext: { id }, ...props }) => {
  const number = useMemo(() => {
    if (typeof document === 'undefined') return null
    const params = new URLSearchParams(document.location.search);
    return params.get("claimno")
  }, [])
  return (
    <ContentLayout title={title[id]} {...props}>
      <ModuleThanks number={number} {...props} />
    </ContentLayout>
  )
}

export default Thanks
